/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.inflearn.com/course/%EC%86%90%EC%97%90-%EC%9D%B5%EB%8A%94-nextjs-part1"
  }, "[손에 익는 Next.js] 공식 문서 훑어보기")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://hajoeun.blog/"
  }, "하조은의 블로그")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/hajoeun_"
  }, "하조은 트위터")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://nextjs.org/"
  }, "Next.js")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://vercel.com/"
  }, "Vercel")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://react.dev/"
  }, "React")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://remix.run/"
  }, "Remix")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://remix.run/blog/remixing-shopify"
  }, "Remixing Shopify")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://astro.build/"
  }, "Astro")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://nextjs.org/docs/app/building-your-application/rendering/server-components"
  }, "React Server Components")), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
